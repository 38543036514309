import Drawer from "../../common/Drawer";
import { Heading } from "@chakra-ui/react";
import Categories from "./Categories";
import { Category } from "../../../../types";

interface Props {
  isOpen: boolean;
  categories?: Category[];
  onClose: () => void;
}

export default function QuestionsDrawer({ isOpen, categories, onClose }: Props) {
  return (
    <>
      <Drawer header="Categories" isOpen={isOpen} onClose={onClose}>
        <div>
          <Categories categories={categories} />
        </div>
      </Drawer>
    </>
  );
}
