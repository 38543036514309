import { Skeleton, Stack } from "@chakra-ui/react";

export default function AnswersPlaceholder() {
  return (
    <Stack spacing="20px">
      {Array.from(Array(6)).map((item, index) => (
        <Skeleton key={`placeholder-${index}`} startColor="bar" endColor="#474663" borderRadius="20px" height="120px" />
      ))}
    </Stack>
  );
}
