import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import DevIcon from "../Icon";

interface Props {
  children: React.ReactNode;
  onRemove?: () => void;
  outlined?: boolean;
  count?: number;
  icon?: string;
}

export default function TagItem({ outlined, children, count, icon, onRemove }: Props) {
  return (
    <Flex
      alignItems="center"
      height={["28px", "28px", "32px", "32px"]}
      justifyContent="space-between"
      bg={outlined ? "transparent" : "bar"}
      borderColor={outlined ? "bar" : "transparent"}
      borderWidth="2px"
      borderRadius="full"
      fontSize={["14px", "14px", "16px", "16px"]}
      p="5px 10px 5px 17px"
      mr="4px"
      mb="4px"
    >
      {icon && (
        <Box mr={1.5}>
          <DevIcon name={icon} />
        </Box>
      )}
      <Text textTransform="uppercase" fontSize="sm" fontFamily={`'Roboto Mono', sans-serif`} color="white">
        {children}
      </Text>
      <Box fontSize="sm" color="gray.500">
        {count}
      </Box>
      <IconButton size="xs" variant="ghost" aria-label="Remove tag" icon={<AiOutlineClose />} onClick={onRemove} />
    </Flex>
  );
}
