import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { AiOutlineSearch } from "react-icons/ai";
import { ChangeEvent, useCallback, useMemo } from "react";
import { debounce } from "lodash";

interface SearchProps {
  onChange: (val: string) => void;
}

export default function Search({ onChange }: SearchProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    }, 200),
    [onChange],
  );

  return (
    <InputGroup marginRight={5}>
      <InputLeftElement justifyContent="center" alignItems="center" width="48px">
        <AiOutlineSearch color="#7C7A85" />
      </InputLeftElement>
      <Input focusBorderColor="#8054FF" onChange={handleChange} borderRadius="full" bg="bar" border="none" placeholder="Search questions" />
    </InputGroup>
  );
}
