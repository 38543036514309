import { gql, useQuery } from "@apollo/client";
import { GetAnswerQuery, GetAnswerQueryVariables } from "../../../../types";

export const GET_ANSWER = gql`
  query GetAnswer($id: ID!) {
    answer(id: $id) {
      id
      title
      description
      complexity
      shortAnswer
      longAnswer
      createdAt
      categories {
        id
        name
        icon
      }
    }
  }
`;

export default function useAnswer(id: string, skip: boolean) {
  const { data, loading, error } = useQuery<GetAnswerQuery, GetAnswerQueryVariables>(GET_ANSWER, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
    skip,
  });

  return {
    answer: data?.answer,
    loading,
    error,
  };
}
