import { Box, Button, Flex, Menu, MenuButton, MenuList, MenuItem, Show } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import QuestionsDrawer from "./QuestionsDrawer";
import { Category } from "../../../../types";
import Tags from "../../common/Tags";
import TagItem from "../../common/Tags/TagItem";
import useFilters from "../hooks/useFilters";
import Search from "../../common/Search";
import { useCallback } from "react";

import { FiChevronDown } from "react-icons/fi";

interface Props {
  categories?: Category[];
}

const getSortStateNames = (sortState: string) => {
  switch (sortState) {
    case "desc":
      return "Newest";
    case "asc":
      return "Oldest";
  }
};

export default function Toolbar({ categories }: Props) {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const { addFilter, setFilters, sortFilter, filtersList, removeFilter } = useFilters();

  const handleSearch = useCallback(
    (val: string) => {
      addFilter({ type: "search", value: val });
    },
    [addFilter],
  );

  return (
    <Box>
      <Flex mb={["20px", "20px", "20px", "30px"]}>
        <Search onChange={handleSearch} />
        <Menu>
          <MenuButton
            textColor="rgba(250, 250, 250, 0.5)"
            bgColor="bar"
            fontWeight="400"
            fontFamily={`'Roboto Mono', sans-serif`}
            fontSize="xs"
            borderRadius="30px"
            as={Button}
            rightIcon={<FiChevronDown />}
          >
            <Box marginRight={2}>{getSortStateNames(sortFilter as string)?.toUpperCase()}</Box>
          </MenuButton>
          <MenuList bgColor="bar" borderColor="dark">
            {["desc", "asc"].map(item => (
              <MenuItem
                bgColor="bar"
                onClick={() => addFilter({ type: "sort", value: item })}
                fontFamily={`'Roboto Mono', sans-serif`}
                fontSize="14px"
                key={item}
              >
                {getSortStateNames(item)}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      <Show below="lg">
        <Flex marginBottom="20px">
          <Button onClick={onToggle} background="primary" size="sm" rounded="lg">
            Categories
          </Button>
        </Flex>
      </Show>
      <Tags onRemoveAll={filtersList.length ? () => setFilters(prev => ({ ...prev, categories: [], complexity: [], tags: [] })) : undefined}>
        {filtersList.map(filter => (
          <TagItem
            icon={filter.icon}
            key={filter.type === "category" ? (filter.value as Category).id : (filter.value as string)}
            onRemove={() => removeFilter(filter)}
          >
            {(filter.value as Category).name || (filter.value as string)}
          </TagItem>
        ))}
      </Tags>

      <QuestionsDrawer isOpen={isOpen} onClose={onClose} categories={categories} />
    </Box>
  );
}
