import { gql, useQuery } from "@apollo/client";
import { Answer, Complexity, GetAllAnswersQuery, GetAllAnswersQueryVariables } from "../../../../types";
import useFilters from "./useFilters";
import { useEffect } from "react";

export const QUESTIONS_LIMIT = 20;

export const GET_ALL_ANSWERS = gql`
  query GetAllAnswers($filters: AnswersFilters, $offset: Int, $limit: Int) {
    answers(filters: $filters, offset: $offset, limit: $limit) {
      answers {
        id
        title
        description
        complexity
        categories {
          id
          name
          icon
        }
        createdAt
      }
      total
    }
  }
`;

export default function useAnswers(initialAnswers?: Answer[], setOffset?: (offset: number) => void) {
  const { categoriesFilter, filters, sortFilter, complexityFilter, searchFilter, tagsFilter } = useFilters();

  // const skip = isEqual(filters, initialFilters);

  useEffect(() => {
    if (filters) {
      setOffset?.(0);
    }
  }, [filters, setOffset]);

  const { data, loading, fetchMore } = useQuery<GetAllAnswersQuery, GetAllAnswersQueryVariables>(GET_ALL_ANSWERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      filters: {
        categories: categoriesFilter?.length ? categoriesFilter.map(item => item.toLowerCase().split(" ").join("-")) : undefined,
        name: searchFilter,
        // @ts-ignore
        complexity: complexityFilter as Complexity[],
        // @ts-ignore
        tags: tagsFilter as string[],
        sort: sortFilter,
      },
      limit: QUESTIONS_LIMIT,
      offset: 0,
    },
  });

  // const result = !skip ? data?.answers?.answers : initialAnswers;

  return {
    answers: data?.answers?.answers || [],
    total: data?.answers?.total || 0,
    loading,
    fetchMore,
  };
}
