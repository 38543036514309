import { m } from "framer-motion";

const loadingCircleVariants = {
  start: {
    opacity: 0.4,
    scale: 0.8,
  },
  end: {
    opacity: 1,
    scale: 1,
  },
};

const loadingCircleTransition = {
  duration: 0.4,
  repeat: Infinity,
  ease: "easeInOut",
};

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const loadingCircle = { height: "10px", width: "10px", borderRadius: "50%", background: "#8054FF", marginLeft: "10px" };

const loadingContainer = { height: "10px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "30px" };

export default function InfiniteLoader({ loading }: { loading: boolean }) {
  return (
    <m.div
      style={{
        ...loadingContainer,
        opacity: loading ? 1 : 0,
      }}
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      <m.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <m.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <m.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
    </m.div>
  );
}
