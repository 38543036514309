import { useState } from "react";
import { Text, Box, IconButton, chakra, Flex, Heading, shouldForwardProp, Tag, Show, Tooltip } from "@chakra-ui/react";
import { AnimatePresence, isValidMotionProp, m } from "framer-motion";
import { format } from "date-fns";

import MarkdownWrapper from "../../common/MarkdownWrapper";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Answer, GetAllAnswersQuery } from "../../../../types";
import Link from "next/link";
import DevIcon from "../../common/Icon";
import useAnswer from "../hooks/useAnswer";
import InfiniteLoader from "../../common/InfiniteLoader";
import { BsArrowUpRightSquare, BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { createSlug } from "../../../utils/create-slug";
import { AnswerBox } from "./AnswerBox";
import AnswerCategoryLink from "./AnswerCategoryLink";

interface AnswerItemProps {
  answer: Omit<Answer, "shortAnswer">;
}

const AnswerItem = ({ answer }: AnswerItemProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { answer: fetchedAnswer, loading } = useAnswer(answer.id as string, !open);

  return (
    <Box
      role="group"
      transition="background 0.5s linear"
      position="relative"
      borderRadius="20px"
      mb="20px"
      p={30}
      bg="bar"
      overflow="hidden"
      _hover={{ bg: !open ? "#2F2E4A" : undefined }}
    >
      <Show breakpoint="(min-width: 992px)">
        {!open && (
          <Box
            position="absolute"
            left={0}
            top="50%"
            visibility="hidden"
            height="70%"
            width="30px"
            filter="blur(35px)"
            bg="primary"
            opacity={0}
            transition="visibility 0.5s, opacity 0.5s linear"
            transform="translateY(-50%)"
            _groupHover={{ visibility: "visible", opacity: 1 }}
          />
        )}
      </Show>

      <Flex justifyContent="space-between">
        <Box width="100%" onClick={() => setOpen(prev => !prev)} cursor="pointer">
          <Heading as="h3" size="md" mb={3} maxWidth="90%" width="100%">
            {answer.title}
          </Heading>
          <Flex justifyContent="flex-start" align="center">
            <Tag size="sm" colorScheme="purple" mr={3}>
              {answer.complexity?.toLowerCase()}
            </Tag>
            {answer.categories?.map(item =>
              item ? <AnswerCategoryLink key={`${answer.id}-${item?.id}`} name={item.name} icon={item.icon as string} /> : null,
            )}
          </Flex>
          <Text color="gray" fontSize="xs" mt={2}>
            {format(Number(answer.createdAt), "dd MMMM yy")}
          </Text>
        </Box>

        <Box position="absolute" top="20px" right="20px">
          <Tooltip label={open ? "Collapse" : "Expand"}>
            <IconButton
              colorScheme="primary"
              aria-label="Expand"
              variant="ghost"
              color="primary"
              isRound
              icon={!open ? <AiOutlinePlus color="primary" /> : <AiOutlineMinus color="primary" />}
              size="lg"
              onClick={e => {
                e.stopPropagation();
                setOpen(prev => !prev);
              }}
            >
              {open ? "Hide answer" : "Show answer"}
            </IconButton>
          </Tooltip>
        </Box>

        <Box position="absolute" bottom="20px" right="20px">
          <Tooltip label="View more">
            <Link href={`/interview-questions/answer/${createSlug(answer.title, answer.id)}`}>
              <IconButton
                colorScheme="primary"
                aria-label="Expand"
                variant="ghost"
                color="white"
                opacity={0.5}
                isRound
                icon={<BsArrowUpRightSquare />}
                size="lg"
              />
            </Link>
          </Tooltip>
        </Box>
      </Flex>

      <AnimatePresence initial={false}>
        {open && (
          <>
            {loading ? (
              <InfiniteLoader loading={loading} />
            ) : (
              <AnswerBox
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: "0.3" }}
              >
                <Box color="primary" textTransform="uppercase" paddingTop="20px" mb={2}>
                  Explanation
                </Box>
                <MarkdownWrapper content={fetchedAnswer?.shortAnswer as string} />
              </AnswerBox>
            )}
          </>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default AnswerItem;
