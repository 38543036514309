import Layout from "../common/Layout";
import { Flex, Heading } from "@chakra-ui/react";
import Container from "../common/Layout/Container";
import Toolbar from "./components/Toolbar";
import AnswerItem from "./components/AnswerItem";
import Sidebar from "./components/Sidebar";
import { Answer, Category } from "../../../types";
import useAnswers from "./hooks/useAnswers";
import AnswersPlaceholder from "./components/AnswersPlaceHolder";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import InfiniteLoader from "../common/InfiniteLoader";

interface QuestionsProps {
  answers?: Answer[];
  categories?: Category[];
  title: string;
}

const LIMIT = 20;

export default function Questions({ title, categories, answers: initialAnswers }: QuestionsProps) {
  const [offset, setOffset] = useState(0);
  const { answers, total, loading, fetchMore } = useAnswers(initialAnswers, setOffset);
  const [loadingMore, setLoadingMore] = useState(false);

  const [ref, inView] = useInView();
  const answersLength = answers?.length || 0;

  useEffect(() => {
    if (inView && !loading && answers && !loadingMore && offset <= total) {
      setLoadingMore(true);
      fetchMore({
        variables: {
          offset: offset + LIMIT,
          limit: LIMIT,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            answers: {
              ...prev.answers,
              answers: [...(prev?.answers?.answers || []), ...(fetchMoreResult?.answers?.answers || [])],
            },
          };
        },
      }).then(() => {
        setLoadingMore(false);
      });
      setOffset(offset + LIMIT);
    }
  }, [answers, answersLength, fetchMore, inView, loading, loadingMore, offset, total]);

  return (
    <>
      <Layout>
        <Heading as="h1" mb={6} fontSize={["lg", "xl", "2xl", "3xl"]} width="100%" textAlign={["center", "center", "center", "left"]}>
          {title}
        </Heading>
        <Flex justifyContent="space-between" alignItems="flex-start" width="100%">
          <Container pr={["0", "0", "0", "30px"]}>
            <Toolbar categories={categories} />
            {loading && !answersLength ? (
              <AnswersPlaceholder />
            ) : (
              <Flex flexDirection="column" justifyContent="space-between" alignItems="stretch">
                {answers?.map(item => (
                  <AnswerItem key={item.id} answer={item} />
                ))}
                {answers?.length && <div ref={ref} style={{ height: "10px" }}></div>}
                <InfiniteLoader loading={loadingMore} />
              </Flex>
            )}
          </Container>
          <Sidebar categories={categories} />
        </Flex>
      </Layout>
    </>
  );
}
