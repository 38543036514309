import { Flex } from "@chakra-ui/react";
import TagItem from "./TagItem";

interface Props {
  onRemoveAll?: () => void;
  children: React.ReactNode;
  icon?: string;
}

export default function Tags({ children, onRemoveAll }: Props) {
  return (
    <Flex alignItems="center" justifyContent="flex-start" flexWrap="wrap">
      {children}
      {onRemoveAll && (
        <TagItem outlined onRemove={onRemoveAll}>
          Remove all
        </TagItem>
      )}
    </Flex>
  );
}
