import { Checkbox, Stack, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import useFilters from "../hooks/useFilters";

export enum ComplexityType {
  EASY = "Easy",
  MEDIUM = "Medium",
  HARD = "Hard",
}

export default function Complexity() {
  const { addFilter, removeFilter } = useFilters();
  const [selected, setSelected] = useState<ComplexityType[]>([]);

  const handleClick = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelected(prev => [...prev, e.target.value]);
        addFilter({ type: "complexity", value: e.target.value });
      } else {
        setSelected(prev => prev.filter(item => item !== e.target.value));
        removeFilter({ type: "complexity", value: e.target.value });
      }
    },
    [addFilter, removeFilter],
  );

  return (
    <Stack mt={1} spacing={1.5}>
      {(Object.keys(ComplexityType) as Array<keyof typeof ComplexityType>).map(key => (
        <Checkbox colorScheme="purple" key={key} size="lg" value={key} isChecked={selected.includes(key as any)} onChange={handleClick}>
          <Text fontSize="sm">{ComplexityType[key]}</Text>
        </Checkbox>
      ))}
    </Stack>
  );
}
