import { gql } from "@apollo/client";
import Head from "next/head";
import { useState } from "react";

import client from "../../src/client";
import { Answer, Category } from "../../types";
import { FilterContext, Filters } from "../../src/components/questions/hooks/useFilters";
import { GET_ALL_ANSWERS, QUESTIONS_LIMIT } from "../../src/components/questions/hooks/useAnswers";
import Questions from "../../src/components/questions";

export interface InterviewQuestionsProps {
  categoryName?: string;
  categoryDescription?: string;
  answers?: Answer[];
  categories?: Category[];
  initialFilters: Filters;
}

const InterviewQuestions = ({ answers, categoryName, categoryDescription, categories, initialFilters }: InterviewQuestionsProps) => {
  const [filters, setFilters] = useState(initialFilters || {});

  const pageTitle = categoryName ? `TOP ${categoryName} Interview Questions` : "TOP IT Interview Questions";
  const metaDescription = categoryDescription
    ? categoryDescription
    : "Ready to ace your IT interview? Our guide includes commonly asked questions for Frontend/Backend development and more. Perfect for all levels of experience, start preparing now and stand out!";

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
        initialFilters,
      }}
    >
      <Head>
        <title>{`${pageTitle} | IReady`}</title>
        <meta name="description" content={metaDescription} key="desc" />
      </Head>
      <Questions title={pageTitle} answers={answers} categories={categories} />
    </FilterContext.Provider>
  );
};

export default InterviewQuestions;

export async function getStaticProps({ params }: { params: { id: string } }) {
  const { data: categories } = await client.query({
    query: gql`
      query GetAllCategories {
        categories {
          id
          name
          description
          parent
          icon
          tags {
            id
            name
          }
        }
      }
    `,
  });

  const { data: answersData } = await client.query({
    query: GET_ALL_ANSWERS,
    variables: {
      limit: QUESTIONS_LIMIT,
      offset: 0,
    },
  });

  return {
    props: {
      answers: answersData.answers?.answers,
      totalAnswers: answersData.answers?.total || 0,
      categories: categories.categories,
      initialFilters: {
        sort: "desc",
      },
    },
  };
}
