import { useMemo } from "react";
import { default as SidebarWrapper } from "../../common/Sidebar";
import { Text, Box } from "@chakra-ui/react";
import { Category } from "../../../../types";
import Categories from "./Categories";
import Complexity from "./Complexity";

interface SidebarProps {
  categories?: Category[]; // TODO fix type here
}

const Sidebar = ({ categories }: SidebarProps) => {
  return (
    <SidebarWrapper>
      <Box mb={5} borderBottom="1px solid #474663" pb={5}>
        <Text mb={3} fontFamily={`'Roboto Mono', sans-serif`} color="#7C7A85" textTransform="uppercase">
          Categories
        </Text>
        <Categories categories={categories} />
      </Box>
      <Box>
        <Text mb={3} fontFamily={`'Roboto Mono', sans-serif`} color="#7C7A85" textTransform="uppercase">
          Difficulty
        </Text>
        <Complexity />
      </Box>
    </SidebarWrapper>
  );
};

export default Sidebar;
